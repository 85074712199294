import React from "react";
import { EToasterVariants } from "../services/types/common.type";

const offerData: any = {}
const userPermission: any = {}

const appState = {

    sideMenuVisibility: false,
    setSideMenuVisibility: (visibility: boolean) => { },
    
    toasterProps: { type: "success", message: "Success Message", visibility: false },
    showToaster: (type: EToasterVariants, message: string) => { },
   
    spinnerVisibility: false,
    setSpinnerVisibility: (visibility: boolean) => { },

    menuIndex: 0,
    setMenuIndex: (index: number) => { },

    offerData,
    setOfferData: (offerData: any) => { },


    userPermission,
    setUserPermission: (userPermission: any) => { },
   
}

export const appContext = React.createContext(appState);


export default appContext;

