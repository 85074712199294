import { LOGIN, USER, UserDispatchTypes, USERUPDATE } from '../actions/UserActionTypes'

type UserI = {
  loading: boolean,
  userDetails?: any
  userPersonal?: any
  userDetailsUpdate?: any
};

const productDefaultState: UserI = {
  loading: false,

};

const userReducer = (state: UserI = productDefaultState, action: UserDispatchTypes): UserI => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: false,
        userDetails: action.payload
      }
    case USER:
      return {
        ...state,
        loading: false,
        userPersonal: action.payload
      }
    case USERUPDATE:
      return {
        ...state,
        loading: false,
        userDetailsUpdate: action.payload
      }
    default:
      return state
  }
}

export default userReducer
