import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export class BaseService {
  
  httpClient: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL});

  rulesEngineClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_RULE_ENGINE_URL,
  });



  constructor() {
    /* HTTP Inceptors with Axios Instance */
    this.httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
      if (!request.headers?.Authorization) {
        request.headers = {
          authorization: localStorage.getItem("accessToken") || "",
          ...request.headers,
        };
      }

      return request;
    });

    /* HTTP Response Interceptor to logout the application on Unauthorized Response */
    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: any) => {
        const err = Promise.reject(error);

        if (error.response.status === 401) {
          this.autoLogout();
        }

        return err;
      }
    );
  }

  async autoLogout(): Promise<void> {
    localStorage.clear();
    window.open(window.location.origin, "_self");
  }
}
