import React, { useRef } from "react";
import "./styles.scss"

type InputFieldProps = {
    textarea?: boolean;
    helpertext?: string;
    error?: string;
    hint?: string;
    mandatory?: boolean;
    register?: any;
    disabled?: boolean;
  } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;


  const InputField: React.FC<InputFieldProps> = ({ register, ...props }) => {


   

    return (
        <div>
            
            <div className="lable-wrapper">
             {props.helpertext}
             </div>

            <input
            
            type="text"
            {...register}
            className="input-wrapper"
            {...props}
           />

          {props.hint && <p className="hint-text">{props.hint}</p>} 
            
        </div>
    )
}

export default InputField
