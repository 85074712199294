import React, { useState } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { EToasterVariants, ToasterProperties } from "./services/types/common.type";
import LandingPage from "./pages/LandingPage";
import appContext from "./context/app.context";
import LoginPage from './pages/LoginPage';
import Spinner from './components/Spinner';
import Toaster from './components/Toaster';
import Organization from 'form/LoginForm/Organization';




function App() {


  const [sideMenuVisibility, setSideMenuVisibility] = useState<boolean>(true);
  const [toasterProps, setToasterProps] = useState<ToasterProperties>({ type: EToasterVariants.success, message: "Success Message", visibility: false });
  const [spinnerVisibility, setSpinnerVisibility] = useState<boolean>(false);
  
  const [offerData, setOfferData] = useState({});
  const [menuIndex, setMenuIndex] = useState(0);

  const [userPermission, setUserPermission] = useState({});
 
 
 
  const showToaster = (type: EToasterVariants, message: string) => {
     
  }
  
  
  const contextItems = {
    sideMenuVisibility,
    setSideMenuVisibility,
    
    toasterProps,
    showToaster,
    
    spinnerVisibility,
    setSpinnerVisibility,

    offerData,
    setOfferData,
    
    menuIndex,
    setMenuIndex,

    userPermission,
    setUserPermission,
   
  }


  return (
       <appContext.Provider value={contextItems}>
         <Router>
          <Routes>
              <Route path="/" element={ <Organization />} />
              <Route path="login" element={ <LoginPage />} />
              {/* <Route path="/" element={localStorage.getItem("accessToken") ? <Navigate replace to="/login" /> : <Navigate replace to="/offers/list" />} /> */}
              {/* <Route path="/" element={localStorage.getItem("accessToken") ? <Navigate replace to="/offers/list" /> : <LoginPage />} /> */}
              {/* <Route path="/offers/*" element={ <LandingPage />} /> */}
          </Routes>
          
            <Spinner />
            <Toaster />

        </Router>
        </appContext.Provider >
  );
}

export default App;
