import { AxiosError } from 'axios'
// import * as _ from "lodash";
import { store } from '../redux/store'
import { API_ENDPOINTS, parseAxiosError } from '../config/config'
import { LOGIN } from '../redux/actions/UserActionTypes'
import { ILoginCredentials } from './interfaces/login.interface'
import { ILoginCredentialsOrganization } from './interfaces/organization.interface'
import { BaseService } from './htttp'
import { IErrorResponse, ISuccessResponse } from './interfaces/common.interface'
import { IAPIReponse } from "./interfaces/common.interface";

export class LoginService extends BaseService {
  
  public async login(credentials: ILoginCredentials): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.LOGIN, credentials)
        
        store.dispatch({
        type: LOGIN,
        payload: data.data
      })
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async createStudenList(credentials: ILoginCredentialsOrganization): Promise<ISuccessResponse | IErrorResponse> {
    
    console.log(credentials,'credentials====>');
    
    
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.ORGANIZATION, credentials)
      console.log(data,'====>');
        store.dispatch({
        type: LOGIN,
        payload: data.data
      })
      return data
     
    } catch (error) {
      return parseAxiosError(error as AxiosError)
      
    }



  }


  
  

}
