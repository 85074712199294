import "./style.scss";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  ILoginResponse,
  ILoginError,
  ILoginCredentials,
} from "../../services/interfaces/login.interface";
import "./style.scss";
import { LoginService } from "../../services/login.service";
import { STATUS } from "../../config/config";
import appContext from "../../context/app.context";
import { EToasterVariants } from "../../services/types/common.type";
import InputField from "../../components/InputField";
import Button from "../../components/Button";

type LoginCredentials = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const loginService: LoginService = new LoginService();
  const { setSpinnerVisibility, showToaster } = useContext(appContext);
  const navigateTo = useNavigate();

  
  const [user, setuser] = useState([]) as any;
  const [pwd, setpwd] = useState([]) as any;

  const { register, handleSubmit }: any = useForm<LoginCredentials>({
    mode: "onChange",
  });

  //const onSubmit = (data:any) => console.log(data);

  const onSubmit = async (logincredentials:any) => {
       console.log(logincredentials,"=====>");


       const inventory = [
        {name: 'slbpvt2', password: "dmrpvt"},
      
        
        
      ];

     // const result = inventory.find( ({ name }) => name === 'cherries' );

     const result = inventory.find((e: any) => e.name === logincredentials.Username);
       console.log(result,'hai');
       
     if(result){
      if(result.password === logincredentials.password) 
      {
         alert("login successfully")
      }
      else{
        alert("invalid password")
      }
     }else{
       alert("invalid username")
     }
     

       
       
  };

  return (
    <div>
      <form
        className="loginForm-container"
        autoComplete="new-password"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group email-ip">
          <InputField
            name={"Username"}
            helpertext="Username"
            type="text"
            register={register("Username")}
          />

          <div className="hidden-height"></div>

          <InputField
            name={"password"}
            helpertext="password"
            type="password"
            register={register("password")}
          />
         <br></br>
          <Button type="submit">Login</Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
