import React from 'react'
import LoginForm from '../../form/LoginForm';
import './styles.scss';

const LoginPage: React.FC = () => {
    return (
        <div className="login-page-wrapper">
           <div className="login-page">
           <h3 className="seller-portal-name-wrapper">Login Form</h3>

            <LoginForm/>
           </div>
        </div>    
    )
}

export default LoginPage
