import React from 'react'
import "./styles.scss"


type IButtonProps = {

    variant?:any;
    border?:string;
    color?:string;
    children?:React.ReactNode;
    height?:string;
    onClick?:() => void;
    radius?:string;
    width?:string;
    marginTop?:string;
    marginLeft?:string;
    type?:any
}


const Button = ({ border,
    variant = "primary",
    color,
    children,
    height,
    onClick,
    radius,
    marginTop,
    marginLeft,
    type,
    width }: IButtonProps) => {
    return (
        <div>
            <button
            type={type || "submit" }
            onClick={onClick}
            className={`Button_wrapper ${variant}`}
            style={{
                backgroundColor:color,
                border,
                borderRadius: radius,
                height,
                width,
                marginTop,
                marginLeft
            }}
            >
                {children}
            </button>
        </div>
    )
}

export default Button
