import { IErrorResponse } from "../services/interfaces/common.interface";
import { AxiosError } from "axios";



export const API_ENDPOINTS = {

  //Login API
  LOGIN: "/users/login",
  OFFER: "/offers",
  
  STUDENT:"http://localhost:2000/persons",
  ORGANIZATION :"http://slbpvt.dinamalar.com/config_staging.php" 
};

export const STATUS = {
  SUCCESS: "success",
  FAILURE: "failure",
};


export const parseAxiosError = (error: AxiosError): IErrorResponse => {
  if (error.isAxiosError && error.response) {
    return { status: STATUS.FAILURE, message: error.message, error, data: {} };
  }

  return { status: STATUS.FAILURE, message: error.message, error, data: {} };
};
